
<--- NORMAL COMPONENTS --->

.Image {
    width: 50vh;
}








@media only screen and (max-width: 800px) {
    .Image {
        width: 100%;
        }
}


<--- Containers and Boxes --->


.BoxList {
    spacing: 
}
.BoxListItem {
    width: calc(20% - 10px);
    float: left;
    background: linear-gradient(White, Wheat);
    box-shadow: 10px 10px grey;
    margin: 0px 10px 10px 0px;
}



.mycode {
    float: center;
    border-left: solid;
    border-width: 10px;
    border-color: RoyalBlue;
    background: LavenderBlush;
    text-align: left;
    width: 100%;
    margin: 5px;
    white-space: pre-wrap;
    font-family: monospace;
    box-shadow: 5px 5px 5px 5px grey;
}
.mycode h3 {
    background: RoyalBlue;
    color: White;
}
.Pre {
    margin-left: 1em;
}

@media only screen and (max-width: 800px) {
     .mycode { 
        width: 100% 
    }
}
@media only screen and (max-width: 1000px) {
    .BoxListItem { 
        width: 100% 
    }
}
<---- Others ---->
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
